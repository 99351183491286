import { isMobileOnly } from "react-device-detect";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import profileBubbleActive from "../../../plc/src/data/icons/bonus-buble-active.svg";
import bonusBubbleActive from "../../../plc/src/data/icons/profile-buble-active.svg";
import bankid from "../../../nbc/src/data/icons/BankID_logo_white.svg";

const backgroundActive = (props) => {
  if (props.cashback) return `url(${bonusBubbleActive})`;
  else if (props.profile) return `url(${profileBubbleActive})`;
};

const inputProps = (props) => {
  if (props.cp)
    return props.error
      ? "is-invalid custom-input number"
      : "custom-input number";
  if (props.customControl) return "custom-control-input";
  if (props.inputLg)
    return props.realityCheck
      ? "form-control input-lg is-invalid "
      : "form-control input-lg";
  if (props.limit)
    return props.theme.skin === "plc"
      ? "custom-input number "
      : "" + "form-control";
  if (props.bonus) return `form-control ${props.propsCol && props.propsCol}`;
  if (props.validform)
    return props.errorprop ? "form-control is-invalid" : "" + "form-control";
  if (props.validformWithdrawal)
    return props.errorprop
      ? "form-control col-4 is-invalid"
      : "" + "form-control col-4";
  if (props.validformWithdrawalBank)
    return props.errorprop
      ? "form-control is-invalid wd-bank"
      : "" + "form-control wd-bank";
  if (props.validFormNumber)
    return `is-invalid form-control number + ${
      props.spanPadding && props.spanPadding
    }`;
  else return `form-control number`;
};

const autoFocusProps = (props) => {
  if (props.autoFocus) {
    return true;
  } else {
    return false;
  }
};

const selectProps = (props) => {
  if (props.selecCenter) {
    if (props.error) return "is-invalid form-control text-center";
    else return "form-control text-center";
  }
  if (props.formControll) return "form-control";
  if (props.formControllSpan) return "form-control arrow";
  if (props.deposit) return "form-control is-invalid";
  else
    return props.theme.skin === "plc"
      ? `custom-input number ${props.emptyPeriod} `
      : "" + `form-control ${props.emptyPeriod}`;
};

const buttonProp = (props) => {
  if (props.btnsolo) return "btn";
  if (props.supportchat) return "sc-gjNHFA geGguR";
  if (props.btn) return `btn btn-first ${props.spancol && props.spancol}`;
  if (props.btnCol) return "btn btn-first col-md-4";
  if (props.btnColMd3) return "btn btn-first col-md-3";
  if (props.btnsecond)
    return `btn btn-second ${props.spancol && props.spancol}`;
  if (props.limit)
    return props.theme.skin === "plc" ||
      props.theme.skin === "pub" ||
      props.theme.skin === "cac" ||
      props.theme.skin === "hyc"
      ? "align-self-end btn btn-first transaction-btn mt-5"
      : "btn btn-first col-md-2";
  if (props.selfExcludionProp) return "btn btn-first col-md-3 mt-3";
  if (props.selfexcludion) {
    if (props.theme.skin === "plc") {
      if (isMobileOnly) return "align-self-end btn btn-first col-md-3 mt-5";
      else return "align-self-middle btn btn-first col-md-3";
    } else return "btn btn-first col-md-3";
  }
  if (props.bonus)
    return "btn btn-first col-xs-12 col-md-12 col-lg-3 text-uppercase";
  if (props.bonusCode) return "btn btn-first col-sm-12 col-md-3 col-ld-3";
  if (props.fourth) return `btn btn-fourth ${props.colfourth}`;
  if (props.third) return `btn btn-third ${props.colthird}`;
  if (props.dialog) return "btn btn-fourth col-md-3 col-sm-4 col-5";
  if (props.banktransfer) return "btn btn-first col-md-3 col-sm-4 col-5";
  if (props.btnsm) return "btn btn-first col-sm-12";
  if (props.dresponse) return "btn btn-first mt-5";
  if (props.deposit)
    return props.hidden ? "btn btn-deposit hidden" : "btn btn-deposit";
  if (props.continue)
    return props.hidden ? "btn btn-continue hidden" : "btn-continue";
  if (props.resetPass) return "btn btn-first col-md-4 mt-4";
  if (props.loginbtn)
    return `${
      props.theme.skin === "abc"
        ? "btn btn-first col-md-3 my-4"
        : "btn btn-first col-md-6 my-4"
    }`;
  if (props.register) return "btn btn-first col-md-3";
  if (props.modal) return "btn btn-nb-bold";
  if (props.hidelink) return props.hidden ? "hidden" : "";
  if (props.gdpr) return `btn btn-first ${props.spancol && props.spancol}`;
  if (props.regIdin)
    return `btn btn-first col-md-4 col-sm-4 col-6 button-bank-id`;
  if (props.btnStripped)
    return `btn btn-stripped ${props.spancol && props.spancol}}`;
  if (props.btnBold) return `btn btn-nb-bold`;
  if (props.btnLight) return `btn btn-nb-light`;
  if (props.btnlogout)
    return props.btnprofilemenu ? `btn bnt-logout-profile` : `btn bnt-logout`;
  if (props.pagelink) return `page-link`;
  if (props.gotogames) return "btn btn-first mt-4";
  if (props.balancecolor) return "btn btn-balance-color";
  else return "";
};

export const StyledLink = styled(Link).attrs((props) => ({
  className: buttonProp(props),
}))`
  ${(props) => {
    if (props.gamesearch) {
      return `
      display:flex;
      height: 70px;
      color: ${props.theme.skin === "fun" ? "#fff" : "var(--tabletext)"};
      border: none;
      /* -webkit-box-shadow: 0px 0px 2px 0px var(--tabletext);
      -moz-box-shadow: 0px 0px 2px 0px var(--tabletext);
      box-shadow: 0px 0px 2px 0px var(--tabletext); */
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: ${
        props.theme.skin === "hyc" || props.theme.skin === "yak"
          ? `var(--bgColorABC)`
          : `var(--bgcolor)`
      };
      `;
    } else if (props.gotogames) {
      return `
        font-family:  ${props.theme.skin === "pub" ? "Ancho" : ""};
        white-space: nowrap;
        font-size: ${props.theme.skin === "pub" ? "0.73rem" : "1rem;"};
      `;
    } else if (props.btnnext) {
      return `
        text-decoration: none !important;
        float: right;
        color: var(--acolor) !important;
        padding-right: 0 !important;

        &:after {
          font-family: 'Material Icons';
          content: 'play_circle';
          vertical-align: middle;
          font-size: 1.7rem;
          padding-left: 1rem;
        }

        @media (max-width: 767px) {
          width: auto;
        }
      `;
    } else if (props.tcpp) {
      return `
      color: #4d79ff;
      text-decoration: underline;
      &:hover {
        color: var(--brandcolor) !important;
      }
      `;
    } else if (props.semob) {
      return `
        background: white;
        margin: 1px;
        display: flex;
        padding: 3px;
        height: 100%;
      `;
    } else if (props.slidcontroll && props.slidcontroll) {
      return `
        font-size: ${
          props.theme.skin === "pub"
            ? "0.93rem"
            : props.theme.skin === "qbc"
              ? ""
              : "1.3rem"
        };
        padding: ${props.theme.skin === "qbc" ? "" : " 15px 20px !important"};
        color: ${
          props.theme.skin === "cac" || props.theme.skin === "hyc"
            ? "inherit"
            : props.theme.skin === "yak"
              ? "#7b38ce"
              : "#fff"
        };
        border: ${props.theme.skin === "pub" ? "3px solid #fff" : ""};
        border-radius:  ${
          props.theme.skin === "pub"
            ? "0.3rem"
            : props.theme.skin === "fun" ||
                props.theme.skin === "cac" ||
                props.theme.skin === "yak"
              ? "30px"
              : props.theme.skin === "hyc"
                ? "10px"
                : ""
        };
        background-color:${props.theme.skin === "nbc" && "#e007ae"};
        width:${
          (props.theme.skin === "yet" || props.theme.skin === "fun") && "100%"
        };

        :hover {
          border-color: ${props.theme.skin === "pub" ? "#9A875D" : ""};
          background-color: ${props.theme.skin === "nbc" && "#fff"};
          color:  ${props.theme.skin === "nbc" && "#350e46"};
        }

        @media(max-width: 768px){
          font-size: ${props.theme.skin === "qbc" ? "" : "1rem"};
        padding: ${
          props.theme.skin === "abc" || props.theme.skin === "qbc"
            ? "10px !important"
            : "15px 20px !important"
        };
        margin-left: ${
          props.theme.skin === "pub" ||
          props.theme.skin === "yet" ||
          props.theme.skin === "fun" ||
          props.theme.skin === "cac" ||
          props.theme.skin === "hyc" ||
          props.theme.skin === "yak" ||
          props.theme.skin === "qbc"
            ? ""
            : "10px"
        };
        
        }
      `;
    }
    if (props.pub) {
      return `
        text-transform: capitalize;
        width: 100px;
        padding: 0.5rem;
        border: ${
          props.goldenborder
            ? "2px solid #9A875D"
            : props.whiteborder
              ? "2px solid #fff"
              : ""
        };
        border-radius: 0.3rem;
        cursor: pointer;
        font-size: 1rem;
        color: ${props.logout ? "#9A875D" : ""};
  
        &:hover {
          border-color: ${props.goldenborder ? "#fff" : "#9A875D"};
        }
        &:disabled {
          &:hover {
            border-color: #fff;
          }
        }
        `;
    }
    if (props.extrasize) {
      return `
        width: 120px;
      `;
    }
    if (props.unclicable) {
      return `
        pointer-events: none;
      `;
    }
  }}
`;

export const StyledNavLink = styled(NavLink).attrs((props) => ({
  className: "btn",
  activeClassName: "active",
}))`
  justify-content: ${({ theme }) => theme.skin === "rac" && "left"};
  font-weight: ${({ theme }) => theme.skin === "pub" && "400"};
  padding: ${({ quickSB }) => quickSB && "0"};
  div {
    border-radius: ${({ theme }) =>
      (theme.skin === "cac" || theme.skin === "qbc") && "50%"};
    border: ${({ theme }) => theme.skin === "cac" && "1px solid #979797"};
    background-color: ${({ theme }) => theme.skin === "hyc" && "#302b50"};
  }
  background-image: ${({ theme }) => theme.skin === "rac" && "none"};
  &.active > div {
    background-image: ${(props) =>
      props.theme.skin !== "plc" ? "" : backgroundActive(props)};
    background-color: ${({ theme }) =>
      theme.skin === "abc"
        ? "#cf1f2c"
        : theme.skin === "rac"
          ? "#f2d500"
          : theme.skin === "hyc"
            ? "#fff"
            : ""};
    border: ${({ theme }) =>
      theme.skin === "nbc"
        ? "5px solid #ed338c"
        : theme.skin === "yet"
          ? "5px solid #fff"
          : theme.skin === "cac" || theme.skin === "qbc"
            ? "2px solid #fdb448"
            : ""};
    border-radius: ${({ theme }) =>
      (theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "yak" ||
        theme.skin === "qbc") &&
      "50%"};
    svg {
      fill: ${({ theme }) =>
        theme.skin === "fun"
          ? "#1e8ea6"
          : theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "qbc"
            ? "transparent"
            : "#fff"};
      margin: auto;
    }
    // Fill for ABC icons
    .st0,
    .st4 {
      fill: #fff;
    }
    // Fill for PUB icons
    #mask-fill-icon {
      fill: ${({ theme }) =>
        theme.skin === "bnk"
          ? "#ae0005"
          : theme.skin === "hyc"
            ? "#070427"
            : theme.skin === "yak"
              ? "#3E076C"
              : "#fff"};
    }
  }
`;

export const StyledButton = styled.button.attrs((props) => ({
  className: buttonProp(props),
}))`
  #btn-live-chat-div {
    position: initial !important;
  }
  ${(props) => {
    if (props.searchbutton) {
      return `
        position: absolute;
        top: ${
          props.gamesearchinlobby
            ? "0"
            : props.mainsearch
              ? "2rem"
              : props.theme.skin === "abc" ||
                  props.theme.skin === "nbc" ||
                  props.theme.skin === "yet" ||
                  props.theme.skin === "fun" ||
                  props.theme.skin === "yak"
                ? "0.45rem"
                : "0.8rem"
        };
        right: ${
          props.gamesearchinlobby
            ? "0px"
            : props.theme.skin === "abc" ||
                props.theme.skin === "nbc" ||
                props.theme.skin === "yet" ||
                props.theme.skin === "fun" ||
                props.theme.skin === "yak"
              ? "0.5rem"
              : "1rem"
        };
        border: none;
        background: transparent;
        color: ${
          props.theme.skin === "abc" || props.theme.skin === "nbc"
            ? "var(--tabletext)"
            : "var(--gameSearch)"
        };
        cursor: pointer;
        &:after {
          font-family: 'Material Icons';
          content: 'cancel';
          vertical-align: middle;
          font-size: 1.5rem;
        }
        @media(max-width: 768px){
          top: ${
            props.gamesearchinlobby
              ? "0"
              : props.mainsearch
                ? "1.4rem"
                : props.theme.skin === "abc" ||
                    props.theme.skin === "nbc" ||
                    props.theme.skin === "yet" ||
                    props.theme.skin === "fun" ||
                    props.theme.skin === "yak"
                  ? "0.45rem"
                  : "0.8rem"
          };
          right : ${
            props.gamesearchinlobby
              ? "0px"
              : props.theme.skin === "abc" ||
                  props.theme.skin === "nbc" ||
                  props.theme.skin === "yet" ||
                  props.theme.skin === "fun" ||
                  props.theme.skin === "yak"
                ? "0"
                : "1rem"
          };
        }
      `;
    }
    if (props.supportchat) {
      return `
      padding:${props.theme.skin === "fun" ? "0.7rem 1.5rem" : "0.5rem 1rem"};
      border: ${props.theme.skin === "pub" && "2px solid #9A875D"};
      transition: all 0.5s ease;
      background-color: ${
        props.theme.skin === "nbc" || props.theme.skin === "yet"
          ? "#fff"
          : props.theme.skin === "pub"
            ? "transparent"
            : props.theme.skin === "fun"
              ? "#fa2c70"
              : props.theme.skin === "cac"
                ? "#fdb448"
                : props.theme.skin === "hyc"
                  ? "var(--racProfileMenuCol)"
                  : props.theme.skin === "yak"
                    ? "#fcc239"
                    : props.theme.skin === "rac"
                      ? ""
                      : "#0554b3 !important"
      };
      
      border-radius: ${
        props.theme.skin === "fun" ||
        props.theme.skin === "cac" ||
        props.theme.skin === "yak"
          ? "30px"
          : "0.5rem"
      };
      color: ${
        props.theme.skin === "nbc"
          ? "#350e46"
          : props.theme.skin === "yet"
            ? "#07415F"
            : props.theme.skin === "yak"
              ? "#7b38ce"
              : "#fff !important"
      };
      /* margin-top: 2rem; */
      font-weight: 700 !important;
      cursor: pointer;
      div {
        color: ${
          (props.theme.skin === "cac" || props.theme.skin === "hyc") &&
          "#fff !important"
        };
      }

      &:hover {
        background-color: ${
          props.theme.skin === "nbc" || props.theme.skin === "pub"
            ? ""
            : props.theme.skin === "yet"
              ? "#358bb7"
              : props.theme.skin === "fun"
                ? "#ee1c61"
                : props.theme.skin === "cac"
                  ? "#edab49"
                  : props.theme.skin === "yak"
                    ? "#e9ac1a"
                    : props.theme.skin === "rac"
                      ? ""
                      : "#0661cf"
        };
        color: ${
          props.theme.skin === "nbc"
            ? "#a18bac"
            : props.theme.skin === "yet" ||
                props.theme.skin === "fun" ||
                props.theme.skin === "yak"
              ? "#fff"
              : ""
        };
        border-color: ${props.theme.skin === "pub" && "#fff"}
      }

      &:before {
          font-family: 'Material Icons';
          content: ${
            props.theme.skin !== "fun" &&
            props.theme.skin !== "cac" &&
            props.theme.skin !== "hyc" &&
            props.theme.skin !== "rac" &&
            "'textsms '"
          };
          color: ${props.theme.skin === "rac" && "#f7e500"};
          vertical-align: middle;
          font-size: 1rem;
        }
        @media (max-width: 767px) {
          /* margin-top: 1rem; */
          @media screen and (orientation: landscape) {
            padding: 0.5rem 1rem;
          }
        }
      `;
    }
    if (props.redemecode) {
      return `
      @media (max-width: 767px) {
        margin: 2rem 1rem;
      }
      
      `;
    }
    if (props.pub) {
      return `
      border: ${props.goldenborder ? "2px solid #9A875D" : "2px solid #fff"};
      border-radius: 0.3rem;
      cursor: pointer;

      &:hover {
        border-color: ${props.goldenborder ? "#fff" : "#9A875D"};
      }
      &:disabled {
        &:hover{ 
          border-color: #fff;
        }
      }
      `;
    }
    if (props.sweVerify) {
      return `
        background: url(${bankid});
        background-color: #15365d;
        background-repeat: no-repeat;
        background-position: center top;
        height: 48px;
      `;
    }
    if (props.gamedeposit) {
      return `
          color: #240090!important;
        `;
    }
    if (props.cancelWithdrawal) {
      return `
          padding: 0px 10px;
          vertical-align: baseline;
        `;
    }
  }}
`;

export const StyledSelect = styled.select.attrs((props) => ({
  className: selectProps(props),
}))``;

export const StyledInput = styled.input.attrs((props) => ({
  className: inputProps(props),
  autoFocus: autoFocusProps(props),
  autoComplete: "off",
}))`
  //remove arrows on all inputs
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  text-transform: ${({ theme, email }) =>
    (theme.skin === "cac" ||
      theme.skin === "fun" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc") &&
    email &&
    "lowercase !important"};
  margin: ${({ validformWithdrawal }) => validformWithdrawal && "auto"};
  max-width: ${({ validformWithdrawal }) => validformWithdrawal && "30%"};
`;

export const StyledFormGroup = styled.div.attrs((props) => ({
  className: `form-group ${props.col} ${props.margin}`,
}))``;

export const StyledFormCheckInline = styled.div.attrs((props) => ({
  className: `form-check-inline ${props.disabled ? " disabled" : ""}`,
}))``;

export const StyledFormCheckLabel = styled.label.attrs((props) => ({
  className: `form-check-label ${props.margin ? "mr-3" : ""}`,
}))``;

export const StyledFlexTableCenter = styled.div.attrs((props) => ({
  className: `flex-table-row justify-content-center`,
}))``;

export const StyledFelxTableRow = styled.div.attrs((props) => ({
  className: `flex-table-row ${props.row && props.row}`,
}))`
  flex-wrap: ${({ total }) => (total ? "nowrap" : "")};
  display: ${({ display }) => (display ? "none" : "")};
`;

export const StyledFlexBetween = styled.div.attrs((props) => ({
  className: `d-flex justify-content-between ${props.mt}`,
}))`
  ${(props) => {
    if (
      props.theme.skin === "abc" ||
      props.theme.skin === "nbc" ||
      props.theme.skin === "yet" ||
      props.theme.skin === "fun" ||
      props.theme.skin === "yak" ||
      props.theme.skin === "rac" ||
      props.theme.skin === "qbc"
    ) {
      return `
      margin-top: 1rem;
      @media (max-width: 767px) {
        button {
          font-size: 0.8rem;
          max-width: 120px;
        }
      }
      `;
    }
    if (
      props.theme.skin === "pub" ||
      props.theme.skin === "cac" ||
      props.theme.skin === "hyc"
    ) {
      return `
      margin-top: 1rem;
      @media (max-width: 767px) {
        button {
          font-size: 0.8rem;
          max-width: 120px;
        }
      }
      `;
    }
  }}
`;

export const StyledFlexCenter = styled.div.attrs((props) => ({
  className: `d-flex justify-content-center ${props.mt}`,
}))``;

export const StyledMx = styled.span.attrs({
  className: "mx-3",
})``;

export const StyledMb = styled.span.attrs((props) => ({
  className: props.mb ? "mb-3" : "mb-4",
}))``;

export const StyledCustomControlLabel = styled.label.attrs({
  className: "custom-control-label",
})``;

export const StyledFlexTable = styled.div.attrs({
  className: "flex-table",
})``;

export const LogoWrapper = styled.div.attrs((props) => ({
  className: `${
    props.theme.skin === "abc" && props.player && isMobileOnly
      ? ""
      : `d-flex ${isMobileOnly ? "logo-abc-mobile" : "logo-abc"} logo-pointer `
  }`,
}))``;

export const VisibilityIcon = styled.div`
  text-align: right;
  position: relative;
  left: ${({ theme, reposition }) =>
    theme.skin === "plc" ? (reposition ? "-60px" : "-30px") : "100%"};
  top: ${({ register, theme }) =>
    theme.skin === "plc"
      ? "10px"
      : register
        ? "-55px"
        : theme.skin === "pub"
          ? "-43px"
          : "-40px"};
  cursor: pointer;
  width: 0px;
  height: 0px;
  margin-left: ${({ reposition, theme }) =>
    theme.skin === "plc" ? 0 : reposition ? "-56px" : "-30px"};

  &:after {
    font-family: "Material Icons";
    content: ${({ show }) => (show ? "'visibility'" : "'visibility_off'")};
    font-size: 1.2rem;
    color: ${({ theme }) => {
      if (theme.skin === "fun") return `#80653d`;
      if (theme.skin === "plc") return `var(--tabletext)`;
      if (theme.skin === "abc") {
        if (theme.current === "dark") {
          return `#fff`;
        } else {
          return `#0555b4`;
        }
      }
      if (theme.skin === "rac") {
        if (theme.current === "dark") {
          return `#fff`;
        } else {
          return `#1d028b`;
        }
      } else {
        return `#fff`;
      }
    }};
  }
`;
