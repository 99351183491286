import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  ModalLogoPLC,
  RegisterCloseButton,
  ModalLogoWrapper,
  ModalClower,
  ModalLogoABC,
  ModalLogoPUB,
  ModalLogoYET,
  ModalLogoFUN,
  ModalLogoCAC,
  ModalLogoHYC,
  ModalLogoYAK,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {PaymentServer} from "../helpers/connectors";

export const SowConsentDialog = ({
  showModal,
  handleProceed,
  handleCancel,
  skin,
    player,
}) => {
  const { t } = useTranslation("");

  useEffect(() => {
    PaymentServer.post(
        "/fhnd/v1/slog/info", {
          id: player.nickName,
          sid: player.sessionID,
          message: "CONSENT DATA-LINK dialog shown"
        }
    )
  }, []);

  return (
    <StyledModal isOpen={showModal}>
      {skin === "plc" && <ModalClower />}
      <ModalLogoWrapper>
        {skin === "plc" && <ModalLogoPLC />}
        {skin === "abc" && <ModalLogoABC />}
        {skin === "pub" && <ModalLogoPUB />}
        {skin === "yet" && <ModalLogoYET />}
        {skin === "fun" && <ModalLogoFUN />}
        {skin === "cac" && <ModalLogoCAC />}
        {skin === "hyc" && <ModalLogoHYC />}
        {skin === "hyc" && <ModalLogoHYC />}
        {skin === "yak" && <ModalLogoYAK />}
      </ModalLogoWrapper>
      <StyledModalBody register>
        {t('label_provide_consent_to_sow_verification')}
      </StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
            dialog
            pub={skin === "pub"}
            onClick={() => handleCancel()}
        >
          {t("label_not_now")}
        </StyledButton>
        <StyledButton
            banktransfer
            pub={skin === "pub"}
            onClick={() => handleProceed()}
        >
          {t("label_yes_now")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
