import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StyledFooterLinks } from "../styledComponents/StyledFooter";
import FooterText from "./FooterText";

const FooterLinks = ({
  pages,
  skin,
  player,
  switchTheme,
  currentTheme,
  server,
}) => {
  const { t, i18n } = useTranslation("");
  const [isHover, setIsHover] = useState(false);
  const { geoIP } = server;
  const { country } = player;
  const isSWEIp =
    geoIP.isoCode.toLowerCase() === "se" || country === "se" ? true : false;

  const isSWEIpAndCasino =
    (geoIP.isoCode.toLowerCase() === "se" || country === "se") &&
    (skin === "nbc" ||
      skin === "yet" ||
      skin === "fun" ||
      skin === "hyc" ||
      skin === "yak")
      ? true
      : false;

  let svgForDarkThema = "rac-theme-switcher-dark";
  if (currentTheme === "dark") {
    svgForDarkThema = "rac-theme-switcher";
    // landlImg = require("../data/img/logo_landl_dark.svg");
  }

  const hover = isHover ? "theme-switch" : "";
  //console.log(pages);

  return (
    <>
      <StyledFooterLinks
        capitalize
        abcextra
        noborder={skin === "pub" || skin === "rac" || skin === "qbc"}
      >
        {pages["index"] && (
          <Link
            to={pages["index"]}
            dangerouslySetInnerHTML={{
              __html: t("label_home", "HOME"),
            }}
          ></Link>
        )}
        <Link
          to={!player ? pages["payments"] : pages["deposit"]}
          dangerouslySetInnerHTML={{
            __html: t("label_deposit", "DEPOSIT"),
          }}
        ></Link>
        <Link
          // className="dissable-nav-link"
          to={!player ? pages["payments"] : pages["withdrawal"]}
          dangerouslySetInnerHTML={{
            __html: t("label_withdrawal", "WITHDRAWAL"),
          }}
        ></Link>
        {pages["support"] && (
          <Link
            to={pages["support"]}
            dangerouslySetInnerHTML={{
              __html: t("label_support", "SUPPORT"),
            }}
          ></Link>
        )}
        {isSWEIpAndCasino
          ? null
          : pages["complaints"] && (
              <Link
                to={pages["complaints"]}
                dangerouslySetInnerHTML={{
                  __html: t("label_complaints", "COMPLAINTS"),
                }}
              ></Link>
            )}
        <Link
          to={pages["my-account"]}
          dangerouslySetInnerHTML={{
            __html: t("label_my_account", "MY ACCOUNT"),
          }}
        ></Link>
        {skin === "rac" && (
          <svg
            onClick={switchTheme}
            className={"fganim " + hover}
            viewBox="0 0 190 190"
          >
            <path
              className={svgForDarkThema}
              onMouseOver={() => setIsHover(true)}
              onMouseOut={() => setIsHover(false)}
              stroke="none"
              d="M138.6,137.9c-10.2,9.4-23.7,15.1-38.6,15.1c-31.5,0-57-25.5-57-57s25.5-57,57-57c16,0,30.5,6.6,40.9,17.3 c-1.6-0.2-3.2-0.3-4.9-0.3c-22.6,0-41,18.4-41,41s18.4,41,41,41C136.9,138,137.8,138,138.6,137.9z" /* style="fill:var(--footerlinks); transition: all 0.2s ease; -webkit-transition: all 0.2s ease;" */
            />
          </svg>
        )}
      </StyledFooterLinks>
      <StyledFooterLinks
        capitalize
        abc
        pubsize={skin === "pub"}
        pubcolor={skin === "pub"}
        noborder={skin === "rac" || skin === "qbc"}
        background={skin === "qbc"}
      >
        {pages["about-us"] && (
          <div>
            <Link
              to={pages["about-us"]}
              dangerouslySetInnerHTML={{ __html: t("label_about_us", "About") }}
            ></Link>
          </div>
        )}
        {pages["terms-and-conditions"] && (
          <div>
            <Link
              to={pages["terms-and-conditions"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_terms_and_conditions", "Terms & Conditions"),
              }}
            ></Link>
          </div>
        )}
        {pages["sports-terms"] && skin !== "plc" && skin !== "bnk" && (
          <div>
            <Link
              to={pages["sports-terms"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_sports_terms_header", "Sports Terms"),
              }}
            ></Link>
          </div>
        )}
        {pages["sports-news"] && skin !== "plc" && skin !== "bnk" && (
          <div>
            <Link
              to={pages["sports-news"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_sports_news_header", "Sports News"),
              }}
            ></Link>
          </div>
        )}
        {pages["privacy-policy"] && (
          <div>
            <Link
              to={pages["privacy-policy"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_privacy_policy", "Privacy & Safety"),
              }}
            ></Link>
          </div>
        )}
        {skin === "rac" && pages["safety"] && (
          <div>
            <Link
              to={pages["safety"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_safety", "Safety"),
              }}
            ></Link>
          </div>
        )}
        {pages["responsible-gaming"] && (
          <div>
            <Link
              to={pages["responsible-gaming"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_responsible_gaming", "Responsibile Gaming"),
              }}
            ></Link>
          </div>
        )}
        {skin === "plc" && pages["anti-witwassen"] && (
          <div>
            <Link
              to={pages["anti-witwassen"]}
              /*target="_blank"
            rel="noreferrer"*/
              dangerouslySetInnerHTML={{
                __html: t("label_anti_witwassen", "AML beleid"),
              }}
            ></Link>
          </div>
        )}
        {pages["news-and-promotions"] && (
          <div>
            <Link
              to={pages["news-and-promotions"]}
              dangerouslySetInnerHTML={{
                __html: t("label_news_and_promotions", "News and Promotions"),
              }}
            ></Link>
          </div>
        )}
        {pages["faq"] && (
          <div>
            <Link
              to={pages["faq"]}
              dangerouslySetInnerHTML={{ __html: t("label_faq", "FAQ") }}
            ></Link>
          </div>
        )}
        {skin === "plc" && pages["handelingsbekwaam"] && (
          <div>
            <Link
              to={pages["handelingsbekwaam"]}
              dangerouslySetInnerHTML={{
                __html: t("label_handelingsbekwaam_title"),
              }}
            ></Link>
          </div>
        )}
        {/* <div>
           {pages["jackpots"] && (
            <Link
              to={pages["jackpots"]}
              dangerouslySetInnerHTML={{
                __html: t("label_jackpots", "Jackpots"),
              }}
            ></Link>
          )}
        </div> */}
        {pages["bonus"] && (
          <div>
            <Link
              to={pages["bonus"]}
              dangerouslySetInnerHTML={{
                __html: t("label_bonus_footer", "Bonus"),
              }}
            ></Link>
          </div>
        )}
        {pages["tournaments"] && (
          <div>
            <Link
              to={pages["tournaments"]}
              dangerouslySetInnerHTML={{ __html: t("label_tournaments") }}
            ></Link>
          </div>
        )}
        <div>
          <a
            rel="noopener noreferrer"
            href={
              skin === "abc"
                ? "https://www.allbritishaffiliates.com/ "
                : skin === "nbc"
                ? "https://www.nobonusaffiliates.com/"
                : skin === "pub"
                ? "https://affiliates.pubcasino.co.uk"
                : skin === "rac"
                ? "https://affiliates.racecasino.com/"
                : skin === "bnk"
                ? "https://demoaffiliates.crassus.online"
                : skin === "yet"
                ? "https://www.yetiaffiliates.com/"
                : skin === "fun"
                ? "https://www.funcasinoaffiliates.com/"
                : skin === "cac"
                ? "https://www.casinocasinoaffiliates.com/"
                : skin === "hyc"
                ? "https://affiliates.hypercasino.com/"
                : skin === "yak"
                ? "https://www.yakocasinoaffiliates.com/"
                : skin === "qbc"
                ? "https://affiliates.quickbet.co.uk/"
                : "https://affiliates.poldercasino.com/"
            }
            target="_blank"
            dangerouslySetInnerHTML={{
              __html: t("label_affiliates", "Affiliates"),
            }}
          ></a>
        </div>
      </StyledFooterLinks>
      {(skin === "plc" || skin === "pub") && <FooterText skin={skin} />}
    </>
  );
};

export default FooterLinks;
