import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  NewWindowStep,
  NewWindowStepDescription,
} from "../styledComponents/StyledSignupLogin";

const StepNewWindow = ({
  src,
  width,
  height,
  setSpinner,
  setStep,
  labelText,
}) => {
  const { t } = useTranslation("");
  const [newWindow, setNewWindow] = useState(null);

  useEffect(() => {
    setSpinner(true);

    // Calculate the center position for the popup window
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // Open a new window
    const win = window.open(
      src,
      "_blank",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (win) {
      // Set the new window reference
      setNewWindow(win);

      // Handle focus and blur events
      const handleFocus = () => {
        win.focus();
      };

      const handleBlur = () => {
        setTimeout(() => {
          if (win && !win.closed) {
            win.focus();
          }
        }, 0);
      };

      window.addEventListener("focus", handleFocus);
      window.addEventListener("blur", handleBlur);

      // Cleanup function to remove event listeners and close the new window
      return () => {
        window.removeEventListener("focus", handleFocus);
        window.removeEventListener("blur", handleBlur);
        if (win) {
          console.log("Closing new window");
          // win.close();
          setStep(0);
          setSpinner(false);
        }
      };
    } else {
      console.error("Failed to open new window");
      setSpinner(false);
    }
  }, [src, width, height, setSpinner]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (newWindow && newWindow.closed) {
        console.log("New window closed");
        setStep(0);
        setSpinner(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [newWindow, setStep, setSpinner]);

  return (
    <NewWindowStep>
      <NewWindowStepDescription>{t(labelText)}</NewWindowStepDescription>
    </NewWindowStep>
  );
};

export default StepNewWindow;
