import { isMobileOnly, isMobile } from "react-device-detect";
import styled from "styled-components";

const limitprop = (props) => {
  if (props.theme.skin === "plc") {
    if (props.selfexcludion) {
      return "flex-table-row justify-content-center py-5 border-bottom";
    } else {
      return `flex-table-row flex-wrap py-5 ${
        props.timelimit ? "align-items-start" : "border-bottom"
      }`;
    }
  } else {
    if (props.selfexcludion) {
      return "flex-table-row justify-content-center py-5";
    } else {
      return `flex-table-row justify-content-center flex-wrap ${
        (props.theme.skin === "pub" ||
          props.theme.skin === "cac" ||
          props.theme.skin === "hyc") &&
        isMobile
          ? "pb-3"
          : props.theme.skin === "rac"
            ? "py-5 border-left border-right border-bottom border-top"
            : "py-5"
      }`;
    }
  }
};

export const StyledLimitWrapper = styled.div.attrs((props) => ({
  className: limitprop(props),
}))`
  align-items: ${({ theme, timelimitAbc, bonuscode }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    timelimitAbc
      ? "baseline"
      : theme.skin === "rac"
        ? bonuscode
          ? "end"
          : "center"
        : "flex-end"};

  .form-control:disabled {
    background-color: #45454573 !important;
  }
  text-align: ${({ verification }) => verification && "left"};
`;

export const StyledLimitSelect = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "plc"
      ? isMobileOnly
        ? "form-group m-0 col-md-3"
        : "form-group m-0 col-md-3 pl-0"
      : "form-group col-md-3",
}))``;

export const RealityCheckWrapper = styled.div.attrs((props) => ({
  className: `flex-table-row justify-content-${
    props.theme.skin === "rac" ? "center pt-3" : "left"
  } `,
}))`
  p {
    color: var(--smallHeaderABC);
  }
  padding-top: ${({ extrapadding }) => (extrapadding ? "1rem" : "")};
  text-align: ${({ extrapadding }) => (extrapadding ? "center" : "")};
`;

export const IncreaseLimit = styled.div.attrs({
  className: "flex-table-row justify-content-center p-3 border-0",
})``;

export const StyledTimeLimit = styled.div.attrs((props) => ({
  className: `form-group col-md-4 ${
    (props.theme.skin === "abc" ||
      props.theme.skin === "nbc" ||
      props.theme.skin === "bnk" ||
      props.theme.skin === "yet" ||
      props.theme.skin === "fun" ||
      props.theme.skin === "yak" ||
      props.theme.skin === "rac" ||
      props.theme.skin === "qbc") &&
    !isMobileOnly
      ? "pb-5"
      : ""
  }`,
}))`
  .invalid-feedback {
    text-align: left;
  }
  label {
    margin-bottom: 0;
    line-height: 0;
  }
  .ver-check {
    .custom-input {
      border-radius: 0.5rem 0 0 0.5rem !important;
    }
    .invalid-feedback {
      margin-top: 55px;
    }
  }
`;

export const StyledSectionTimeLimit = styled.div.attrs((props) => ({
  className: `flex-table-row ${props.padding} justify-content-center ${props.border}`,
}))``;

export const MessageLock = styled.div.attrs({
  className: `flex-table-row text-center p-3`,
})``;

export const StyledIndefiniteTimeout = styled.div.attrs({
  className:
    "flex-table-row justify-content-center p-3 border border-top-0 rounded-bottom",
})``;

export const ExcludedGamesWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" || props.theme.skin === "bnk"
      ? "m-auto w-100 pt-5"
      : "border-bottom m-auto w-100",
}))``;

export const SectionExcludedGameWeapper = styled.div.attrs((props) => ({
  className: `flex-table-row justify-content-center ${props.excludedlimits}`,
}))`
  ${(props) => {
    if (props.spanStyle) {
      return `
      .btn {
        font-size: inherit;
      }

      @media (max-width: 767px) {
        .form-check-inline {
          font-size: 0.7rem;
        }
      }
      `;
    }
    if (props.realitycheck) {
      return `
      border-left: 1px solid var(--bordercolor) !important;
      border-right: 1px solid var(--bordercolor)!important;
      `;
    }
  }}
`;

export const StyledBlockedGames = styled.div.attrs({
  className:
    "flex-table-row flex-table-row-header justify-content-center border-top border-bottom",
})``;

export const BlockedGamesTableCell = styled.div.attrs({
  className: "flex-table-cell title-content col-md-4",
})``;

export const StyledCooldownExplanation = styled.div.attrs((props) => ({
  className: props.theme.skin === "plc" && "col-12 pl-0",
}))`
  width: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "rac") &&
    "610px"};
  ${(props) => {
    if (props.cooldownprop) {
      return `
      
      @media (max-width: 767px) {
        position: relative;
        top: -100px;
        left: 15px;
      }
        
      `;
    }
  }}
`;
export const StyledCooldownExplanationSmall = styled.small.attrs({
  className: "form-text text-muted",
})``;
