import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../plc/src/data/scss/layout/flex-table.scss";
import { isMobile } from "react-device-detect";
import {
  StyledTableContent,
  StyledTabPanel,
} from "../styledComponents/AccountPage";
import {
  GDPRStatusCell,
  GDPRStepWrapper,
  GDPRTableContent,
  GDPRTableContentLeft,
  RacGDPRTableContent,
  ResetButtonWrapper,
  StyledCustomCheckboxWrapper,
} from "../styledComponents/StyledSignupLogin";
import {
  StyledButton,
  StyledCustomControlLabel,
  StyledFelxTableRow,
  StyledInput,
} from "../styledComponents/CommonStyledComponents";
import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";

const RegisterStepGDPR = ({
  setSpinner,
  formData,
  handleChange,
  navigation,
  complete,
  errors,
  pages,
  skin,
}) => {
  const {
    addictionPolicy,
    termsAndConditions,
    legalCapacity,
    receiveNews,
    receiveSMS,
    amlPolicy,
    gdprAccepted,
    receiveBonus,
  } = formData;

  const { go, pause } = navigation;
  const { t } = useTranslation("");
  // const baseUrl = `/${i18n.language || window.localStorage.i18nextLng || "en"}`;
  const isUK = formData.country === "gb" ? true : false;
  const isSE = formData.country === "se" ? true : false;
  const prevStep = isUK ? "stepUK" : isSE ? "secondSE" : "second";
  if (complete !== null) {
    setSpinner(false);
    go("success");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(errors);
  }, []);

  const processesRegister = () => {
    setSpinner(true);
    pause();
    if (complete !== null) {
      setSpinner(false);
      go("success");
    }
  };

  const mandatoryCheck =
    skin === "plc"
      ? termsAndConditions &&
        addictionPolicy &&
        gdprAccepted &&
        amlPolicy &&
        legalCapacity
      : termsAndConditions && gdprAccepted;

  return (
    <GDPRStepWrapper>
      {skin === "rac" && (
        <PageheadlinePar
          dangerouslySetInnerHTML={{ __html: t("label_register_account") }}
        ></PageheadlinePar>
      )}
      <StyledTabPanel
        as="div"
        extrapadding={skin === "rac"}
        racgdpr={skin === "rac"}
      >
        {skin === "rac" && (
          <StyledFelxTableRow
            row={skin === "rac" ? "row py-3" : "row"}
            display={isMobile}
          >
            <RacGDPRTableContent
              mobile={isMobile}
              dangerouslySetInnerHTML={{
                __html: t("label_category"),
              }}
            ></RacGDPRTableContent>
            <GDPRTableContent
              mobile={isMobile}
              dangerouslySetInnerHTML={{
                __html: t("label_description"),
              }}
            ></GDPRTableContent>
            <GDPRStatusCell
              mobile={isMobile}
              dangerouslySetInnerHTML={{
                __html: t("label_status"),
              }}
            ></GDPRStatusCell>
          </StyledFelxTableRow>
        )}
        <StyledFelxTableRow row={skin === "rac" ? "row py-3" : "row"}>
          {skin === "rac" && (
            <RacGDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t("label_terms"),
                }}
              ></GDPRTableContentLeft>
            </RacGDPRTableContent>
          )}
          <GDPRStatusCell mobile={isMobile}>
            <StyledTableContent>
              <StyledCustomCheckboxWrapper>
                <StyledInput
                  customControl
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                  name="termsAndConditions"
                  id="termsAndConditions"
                  checked={termsAndConditions}
                />
                <StyledCustomControlLabel htmlFor="termsAndConditions"></StyledCustomControlLabel>
              </StyledCustomCheckboxWrapper>
            </StyledTableContent>
          </GDPRStatusCell>
          <GDPRTableContent mobile={isMobile}>
            <GDPRTableContentLeft
              spancenter={skin === "rac"}
              dangerouslySetInnerHTML={{
                __html: t("label_terms_description", {
                  link: pages["terms-and-conditions"],
                }), //I agree with the terms and conditions
              }}
            ></GDPRTableContentLeft>
          </GDPRTableContent>
        </StyledFelxTableRow>
        {skin === "plc" && (
          <StyledFelxTableRow row={"row"}>
            <GDPRStatusCell mobile={isMobile}>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    name="addictionPolicy"
                    id="addictionPolicy"
                    checked={addictionPolicy}
                  />
                  <StyledCustomControlLabel htmlFor="addictionPolicy"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </GDPRStatusCell>
            <GDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t(
                    "label_addiction_policy_description",
                    { link: pages["responsible-gaming"] } //I agree to the addiction policy
                  ),
                }}
              ></GDPRTableContentLeft>
            </GDPRTableContent>
          </StyledFelxTableRow>
        )}
        <StyledFelxTableRow row={skin === "rac" ? "row py-3" : "row"}>
          {skin === "rac" && (
            <RacGDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{ __html: t("label_privacy_policy") }}
              ></GDPRTableContentLeft>
            </RacGDPRTableContent>
          )}
          <GDPRStatusCell mobile={isMobile}>
            <StyledTableContent>
              <StyledCustomCheckboxWrapper>
                <StyledInput
                  customControl
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                  name="gdprAccepted"
                  id="gdprAccepted"
                  checked={gdprAccepted}
                />
                <StyledCustomControlLabel htmlFor="gdprAccepted"></StyledCustomControlLabel>
              </StyledCustomCheckboxWrapper>
            </StyledTableContent>
          </GDPRStatusCell>
          <GDPRTableContent mobile={isMobile}>
            <GDPRTableContentLeft
              spancenter={skin === "rac"}
              dangerouslySetInnerHTML={{
                __html: t("label_privacy_policy_description", {
                  link: pages["privacy-policy"],
                }), //I agree with the privacy policy
              }}
            ></GDPRTableContentLeft>
          </GDPRTableContent>
        </StyledFelxTableRow>
        {skin === "plc" && (
          <StyledFelxTableRow row={"row"}>
            <GDPRStatusCell mobile={isMobile}>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    name="amlPolicy"
                    id="amlPolicy"
                    checked={amlPolicy}
                  />
                  <StyledCustomControlLabel htmlFor="amlPolicy"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </GDPRStatusCell>
            <GDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t(
                    "label_aml_policy_description",
                    {
                      link: pages["anti-witwassen"],
                    } //I agree with the anti-money laundering policy
                  ),
                }}
              ></GDPRTableContentLeft>
            </GDPRTableContent>
          </StyledFelxTableRow>
        )}
        {skin === "plc" && (
          <StyledFelxTableRow row={"row"}>
            <GDPRStatusCell mobile={isMobile}>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    name="legalCapacity"
                    id="legalCapacity"
                    checked={legalCapacity}
                  />
                  <StyledCustomControlLabel htmlFor="legalCapacity"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </GDPRStatusCell>
            <GDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t("label_legal_capacity_description", {
                    link: pages["handelingsbekwaam"],
                  }), //I confirm that I have the legal capacity to act
                }}
              ></GDPRTableContentLeft>
            </GDPRTableContent>
          </StyledFelxTableRow>
        )}

        <StyledFelxTableRow row={skin === "rac" ? "row py-3" : "row"}>
          {skin === "rac" && (
            <RacGDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t("label_email_marketing"),
                }}
              ></GDPRTableContentLeft>
            </RacGDPRTableContent>
          )}
          <GDPRStatusCell mobile={isMobile}>
            <StyledTableContent>
              <StyledCustomCheckboxWrapper>
                <StyledInput
                  customControl
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                  name="receiveNews"
                  id="receiveNews"
                  checked={receiveNews}
                />
                <StyledCustomControlLabel htmlFor="receiveNews"></StyledCustomControlLabel>
              </StyledCustomCheckboxWrapper>
            </StyledTableContent>
          </GDPRStatusCell>
          <GDPRTableContent mobile={isMobile}>
            <GDPRTableContentLeft
              spancenter={skin === "rac"}
              dangerouslySetInnerHTML={{
                __html: t(
                  "label_email_marketing_description" //Yes I would like to receive news and promotions
                ),
              }}
            ></GDPRTableContentLeft>
          </GDPRTableContent>
        </StyledFelxTableRow>
        <StyledFelxTableRow row={skin === "rac" ? "row py-3" : "row"}>
          {skin === "rac" && (
            <RacGDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t("label_sms_marketing"),
                }}
              ></GDPRTableContentLeft>
            </RacGDPRTableContent>
          )}
          <GDPRStatusCell mobile={isMobile}>
            <StyledTableContent>
              <StyledCustomCheckboxWrapper>
                <StyledInput
                  customControl
                  type="checkbox"
                  onChange={(e) => handleChange(e)}
                  name="receiveSMS"
                  id="receiveSMS"
                  checked={receiveSMS}
                />
                <StyledCustomControlLabel htmlFor="receiveSMS"></StyledCustomControlLabel>
              </StyledCustomCheckboxWrapper>
            </StyledTableContent>
          </GDPRStatusCell>
          <GDPRTableContent mobile={isMobile}>
            <GDPRTableContentLeft
              spancenter={skin === "rac"}
              dangerouslySetInnerHTML={{
                __html: t("label_sms_marketing_description"),
              }}
            ></GDPRTableContentLeft>
          </GDPRTableContent>
        </StyledFelxTableRow>
        {/* receiveBonus */}
        {skin === "plc" && (
          <StyledFelxTableRow row={"row"}>
            <GDPRStatusCell mobile={isMobile}>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    name="receiveBonus"
                    id="receiveBonus"
                    checked={receiveBonus}
                  />
                  <StyledCustomControlLabel htmlFor="receiveBonus"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </GDPRStatusCell>
            <GDPRTableContent mobile={isMobile}>
              <GDPRTableContentLeft
                dangerouslySetInnerHTML={{
                  __html: t(
                    "label_receive_bonus_description",
                    "ja ik ontvang graag bоnussеn"
                  ), //yes i would like to receive bоnussеn
                }}
              ></GDPRTableContentLeft>
            </GDPRTableContent>
          </StyledFelxTableRow>
        )}
      </StyledTabPanel>

      <ResetButtonWrapper gdpr>
        {(skin === "abc" ||
          skin === "nbc" ||
          skin === "rac" ||
          skin === "yet" ||
          skin === "fun" ||
          skin === "cac" ||
          skin === "hyc" ||
          skin === "yak" ||
          skin === "qbc") && (
          <StyledButton
            fourth
            pub={skin === "pub"}
            colfourth={"col-md-4"}
            type="button"
            onClick={() => go(prevStep)}
          >
            {t("label_go_back", "Previous")}
          </StyledButton>
        )}
        {
          /* <div className="form-group">
        {errors !== null ? <p>{`Errors: ${errors[0].message}`}</p> : ""}
        {complete !== null ? <p>{complete}</p> : ""}*/
          mandatoryCheck && (
            <StyledButton
              gdpr
              pub={skin === "pub"}
              spancol={skin === "plc" ? "mt-4 col-md-4" : "col-md-4"}
              type="submit"
              onClick={() => processesRegister()}
              dangerouslySetInnerHTML={{
                __html: t("label_register"),
              }}
            ></StyledButton>
          )
          /* </div> */
        }
      </ResetButtonWrapper>
      {/* {registerErrors !== null && (
        <div className="row mt-3">
          <div class="alert alert-danger w-100" role="alert">
            {registerErrors[0].message}
          </div>
        </div>
      )} */}
    </GDPRStepWrapper>
  );
};

export default RegisterStepGDPR;
