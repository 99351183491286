import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import { StyledLimitWrapper } from "../styledComponents/StyledLimits";
import { QUERY_GET_DATA_LINK } from "../graphql/queries";
// import IFrame from "./IFrame";
import StepNewWindow from "./StepNewWindow";
import { SOW_CONSENT_FORM } from "../helpers/constants";
import { PaymentServer } from "../helpers/connectors";
import configData from "abc/src/config.json";
import { SowConsentDialog } from "../modal/SowConsentDialog";
import { FullPageOverlay } from "../styledComponents/StyledGame";
import { FrameBox } from "../styledComponents/StyledDepositAndWithdrawal";
import { TransactionsDescription } from "../styledComponents/AccountPage";

//useDialog - when this component is used with popup
//showDialog - should dialog be displayed when useDialog is true
const EddRgComponent = ({ player, setSpinner, dataLink, skin, useDialog }) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [redirectUrl, setRedirectUrl] = useState("");
  //when data link is not empty load data link in new window
  const [step, setStep] = useState(useDialog ? 2 : 0);
  //step 0 - get data link button
  //step 1 - continue in new window button
  //
  const [showModal, setShowModal] = useState(true);

  //localStorage.getItem(SOW_CONSENT_FORM)

  // useEffect(() => {
  //   if (dataLink && dataLink !== "") {
  //     setRedirectUrl(dataLink);
  //     setStep(1);
  //   }
  // }, [dataLink]);

  useEffect(() => {
    const handler = (event) => {
      // console.log("FRAME EVENT: " + JSON.stringify(event));
      // console.log(typeof event.data)
      // console.log("origin" + event.origin)
      // console.log("origin 2: " + window.location.origin)
      if (event.origin === window.location.origin) {
        // console.log("same origin!");
        let data = null;
        if (typeof event.data !== "undefined") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            data = event.data;
          }
          // console.log("DATA: " + data)
          if (typeof data.stepEcospend !== "undefined") {
            // console.log(data.message)
            // try {
            // let msg = data.message
            // if (msg.stepEcospend && msg.stepEcospend !== "") {
            setStep(0);
            setSpinner(false);
            window.location.reload();
            return false;
            // }
            // } catch (e) {
            //   console.log(e);
            // }
          }
        }
      }
    };

    // console.log("add listener");

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, []); // empty array => run only once

  async function getDataLink() {
    // console.log("getDataLink", player.nickName);
    setSpinner(true);
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_DATA_LINK,
      variables: {
        nickName: player.nickName,
      },
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      setSpinner(false);
      return false;
    } else {
      //url is fetched
      setSpinner(false);
      data && setRedirectUrl(data.getDataLink.url);
      setStep(1);
    }

    return true;
  }

  return (
    <>
      <TransactionsDescription
        dangerouslySetInnerHTML={{
          __html: t("label_edd_btn_text"),
        }}
      ></TransactionsDescription>
      <StyledLimitWrapper selfexcludion bonuscode>
        {step === 0 && (
          <StyledButton
            selfexcludion
            onClick={() => getDataLink()}
            dangerouslySetInnerHTML={{
              __html: t("label_get_data_link", "Get Data Link"),
            }}
          ></StyledButton>
        )}
        {step === 1 && (
          <StyledButton
            selfexcludion
            redemecode
            onClick={() => {
              setStep(4);
              setSpinner(true);
            }}
            dangerouslySetInnerHTML={{
              __html: t(
                "label_proceed_in_new_window",
                "Click to proceed in new window"
              ),
            }}
          ></StyledButton>
        )}
        {step === 4 && redirectUrl !== "" && (
          // <IFrame
          //     src={redirectUrl}
          //     className="pnp-iframe"
          //     width={600}
          //     height={600}
          //     setDepositStep={null}
          //     requestMethod={""}
          //     requestParams={{}}
          //     srcDoc={null}
          // />
          <FullPageOverlay>
            <FrameBox>
              <StepNewWindow
                setStep={setStep}
                height={600}
                labelText={"label_edd_rg_in_progress"}
                setSpinner={setSpinner}
                src={redirectUrl}
                width={600}
              ></StepNewWindow>
            </FrameBox>
          </FullPageOverlay>
        )}
        {step === 2 && (
          <SowConsentDialog
            showModal={showModal}
            handleProceed={() => {
              // setShowEddRgComponent(true);
              // setSpinner(true);
              setRedirectUrl(dataLink);
              setStep(4);
              // setShowModal(false)
              // setShowSowConsentDialog(false);
              localStorage.removeItem(SOW_CONSENT_FORM);
              PaymentServer.post("/fhnd/v1/slog/info", {
                id: player.nickName,
                sid: player.sessionID,
                message: "CONSENT DATA-LINK dialog PROCEED",
              });
            }}
            handleCancel={() => {
              // setShowSowConsentDialog(false)
              setShowModal(false);
              localStorage.removeItem(SOW_CONSENT_FORM);
              PaymentServer.post("/fhnd/v1/slog/info", {
                id: player.nickName,
                sid: player.sessionID,
                message: "CONSENT DATA-LINK dialog NOT NOW",
              });
            }}
            player={player}
            skin={skin}
          />
        )}
      </StyledLimitWrapper>
    </>
  );
};

export default EddRgComponent;
